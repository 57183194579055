import React from 'react';
import {createRoot} from 'react-dom/client';
import store from "./store/store";
import { Provider } from 'react-redux'
import Layout from "./components/Layout";
import './styles/reset.css'
import './styles/fonts.css'
import './styles/globals.css'
import './styles/main.css'

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Layout/>
  </Provider>
)