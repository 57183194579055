import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loader from "./Loader/Loader";
import {getProjectsDocsList, getProjectsList} from "../store/helpers";

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = ({

})

const Layout$ = (props) => {

  const [projects, setProjects] = useState(undefined)

  const onLoad = async () => {
    let projects = await getProjectsList()
    let projectsDocs = await getProjectsDocsList()
    if (projects?.error || projectsDocs?.error) {
      setProjects(false)
      return
    }

    projects = projects[0]
    let list = []

    projectsDocs.forEach( doc => {
      let obj = {}
      obj.name = doc.split('_api')?.[0]
      let project = projects.find( x => x.name === obj.name )
      if (project) {
        Object.assign(obj, project)
      }

      if (!obj.flag_exists_front) obj.icon = 'https://tmc.lll.org.ua/_storage/api.png'
      else obj.icon = `https://tmc.lll.org.ua/${obj.name}/favicon.ico`

      list.push(obj)
    } )
    setProjects(list)
  }

  useEffect( () => {
    onLoad().then(null)
  }, [] )   // eslint-disable-line

  if (projects === undefined) return(
    <div className="globalWrapper d-flex fd-column ai-center jc-center">
      <Loader />
      <p>Загрузка</p>
    </div>
  )

  if (projects === false) return(
    <div className="globalWrapper d-flex fd-column ai-center jc-center">
      <p>Ошибка загрузки списка проектов</p>
    </div>
  )

  return(
    <div className="globalWrapper d-flex fd-column ai-center">
      <h1 className="fz-20 mt-30">API 911 Swagger документация</h1>
      <div className="docsContainer d-flex fd-column mt-20">
        { projects.map( (item, index) =>
          <a
            key={index}
            target="_blank"
            rel="noreferrer"
            href={`https://tmc.lll.org.ua/documentation_api/docs/${item.name}_api/`}
            // href={`https://tmc.lll.org.ua/${item.name}_api/api-docs`}
          >
            <div className="listItem d-flex">
              <div className="listItemIcon" style={{backgroundImage: `url('${item.icon}')`}} />
              <div className="d-flex fd-column ml-13">
                <p className="listItemName">{item.name}_api</p>
                <p className="mt-3">{item.title}</p>
                <p className="listItemDescription mt-3">{item.description}</p>
              </div>
            </div>
          </a>
        ) }
      </div>
    </div>
  )
}

const Layout = connect(mapStateToProps, mapDispatchToProps)(Layout$)

export default Layout