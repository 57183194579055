import {
  KEK,
} from "./constants";

const initState = {

}

const reducer = (state = initState, action) => {
  switch (action.type) {

    case KEK:
      return state



    default:
      return state
  }
}

export default reducer
