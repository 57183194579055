import React from "react"
import './Loader.css'

const Loader = (props) => {
  return(
    <div className={props.isWrapper ? 'loaderWrapper' : ''}>
      <div className="loaderContainer">
        <div className="loaderInner loaderInnerOne" style={{borderBottom: `3px solid ${props.color}`}}> </div>
        <div className="loaderInner loaderInnerTwo" style={{borderRight: `3px solid ${props.color}`}}> </div>
        <div className="loaderInner loaderInnerThree" style={{borderTop: `3px solid ${props.color}`}}> </div>
      </div>
    </div>
  )
}

export default Loader